<template>
<div>
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-8">
          <b-card>
            <div slot="header">
            <strong>Unidades de Compra</strong>
          </div>
        <table class="table table-hover">
            <thead>
            <tr>
                <th></th>
                <th>Sigla</th>
                <th>Descrição</th>
                <th>Conv.</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem, index) in mun" :key="index">
                    <td>
                      <b-form-checkbox
                        v-model="mun[index].mun_active" 
                        switch
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input v-model="mun[index].mun_sigla"></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="mun[index].mun_name"></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control" v-model="mun[index].mun_conv"></vue-autonumeric>
                    </td>
                    <td>
                        <button class="btn btn-warning" @click="editar(index)">Salvar</button>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td><b-form-input v-model="munSigla"></b-form-input></td>
                    <td><b-form-input v-model="munName"></b-form-input></td>
                    <td><vue-autonumeric class="form-control" v-model="munConv"></vue-autonumeric></td>
                    <td><button class="btn btn-success" @click="adicionar()">Adicionar</button></td>
                </tr>
            </tbody>
        </table>
          </b-card>
        </div>
        <div class="col-md-4">
          Olar
        </div>
        
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      materialItem: [],
      original: [],
      munSearch: '',
      sessao: '',
      loading: false,
      mun: [],
      munName: '',
      munSigla: '',
      munConv: 1,
      munActive: 1
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.listar('pro_Material_Unidade', 'mun_name', '').then(
          (response) => {
            this.mun = response
            this.loading = false
          }, (response) => {})
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    adicionar: function () {
      if (this.munSigla === '' || this.munName === '' || this.munConv === 0) {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      this.munSigla = this.munSigla.replace(/[^A-Z0-9]+/ig, '').toUpperCase()
      if (this.munSigla.length > 10) {
        this.$swal('O tamanho máximo do campo de identificação é de 10 caracteres')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material_Unidade', 'mun_ID', '(mun_sigla = ' + this.munSigla + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.munSigla) {
            this.$swal('Já existe um registro com esta sigla: ID ' + response + '')
          } else {
            this.$swal('Erro ao inserir: ' + JSON.stringify(response))
          }
        }, (response) => {
          this.loading = true
          this.inserir('pro_Material_Unidade', {resource: [ {mun_sigla: this.munSigla, mun_name: this.munName, mun_conv: this.munConv, mun_active: 1} ]}).then(
            (response) => {
              this.munName = ''
              this.munSigla = ''
              this.munConv = ''
              this.verificaSessao()
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    editar: function (index) {
      if (this.mun[index].mun_sigla === '' || this.mun[index].mun_name === '' || this.mun[index].mun_conv === 0) {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material_Unidade', 'mun_ID', '(mun_sigla = ' + this.mun[index].mun_sigla + ') AND (mun_ID != ' + this.mun[index].mun_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.munSigla) {
            this.$swal('Já existe um registro com esta sigla: ID ' + response + '')
          } else {
            this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          }
        }, (response) => {
          this.loading = true
          this.atualizar('pro_Material_Unidade', {resource: [ {mun_sigla: this.mun[index].mun_sigla, mun_name: this.mun[index].mun_name, mun_conv: this.mun[index].mun_conv, mun_active: this.mun[index].mun_active} ]}, 'mun_ID = ' + this.mun[index].mun_ID).then(
            (response) => {
              this.verificaSessao()
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>